// Imports from the node modules folder
import 'htmx.org';
import 'bootstrap';
import Alpine from 'alpinejs';

window.Alpine = Alpine
Alpine.start()

// htmx.config.useTemplateFragments = true;

// Imports for my own custom code.
import './main.scss';


// Checks if we are using hot reloading in the development server and allows it to work.
// You will need to place this at the bottom of each of the entrypoints you are using.
if (module.hot) {
    module.hot.accept()
}

